import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Typography, Box, Paper, TextField, Grid, IconButton } from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import config from '../../config';
import './BlogCategoriesPage.css';

const BlogCategoriesPage = () => {
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState('');
  const [editCategory, setEditCategory] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = () => {
    axios.get(`${config.baseUrl}/getcategorieswithcount`)
      .then(response => {
        setCategories(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the categories!", error);
        setError('Failed to fetch categories. Please try again later.');
      });
  };

  const handleAddCategory = () => {
    axios.post(`${config.baseUrl}/storeCategory`, { name: newCategory })
      .then(response => {
        fetchCategories();
        setNewCategory('');
        setDialogOpen(false);
      })
      .catch(error => {
        console.error("There was an error adding the category!", error);
        setError('Failed to add category. Please try again later.');
      });
  };

  const handleEditCategory = () => {
    axios.put(`${config.baseUrl}/blog-categories/${editCategory.id}`, { name: editCategory.name })
      .then(response => {
        fetchCategories();
        setEditCategory(null);
        setEditDialogOpen(false);
      })
      .catch(error => {
        console.error("There was an error updating the category!", error);
        setError('Failed to update category. Please try again later.');
      });
  };

  const handleDeleteCategory = (id) => {
    axios.delete(`${config.baseUrl}/deleteCategory/${id}`)
      .then(response => {
        fetchCategories();
      })
      .catch(error => {
        console.error("There was an error deleting the category!", error);
        setError('Failed to delete category. Please try again later.');
      });
  };

  return (
    <Box className="categories-page">
      <Typography variant="h4" component="h1" textAlign="center" marginBottom="20px">
        Blog Categories Management
      </Typography>
      {error && <Typography variant="body1" color="error" textAlign="center">{error}</Typography>}
      <Box textAlign="center" marginBottom="20px">
        <Button variant="contained" color="primary" onClick={() => setDialogOpen(true)} className="add-category-button">
          Add New Category
        </Button>
      </Box>
      <Grid container spacing={2}>
        {categories.map(category => (
          <Grid item xs={12} md={6} lg={4} key={category.id}>
            <Paper elevation={3} className="category-item">
              <Box padding={2}>
                <Typography variant="h6" className="category-name">{category.name}</Typography>
                <Typography variant="body2" className="category-count">{category.blog_posts_count} posts</Typography>
                <Box display="flex" justifyContent="space-between" alignItems="center" marginTop={2}>
                  <IconButton className="edit-button" onClick={() => {
                    setEditCategory(category);
                    setEditDialogOpen(true);
                  }}>
                    <EditIcon />
                  </IconButton>
                  <IconButton className="delete-button" onClick={() => handleDeleteCategory(category.id)}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} fullWidth maxWidth="sm">
        <DialogTitle>Add New Category</DialogTitle>
        <DialogContent>
          <TextField
            label="Category Name"
            variant="outlined"
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
            margin="normal"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleAddCategory} color="primary">
            Add Category
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)} fullWidth maxWidth="sm">
        <DialogTitle>Edit Category</DialogTitle>
        <DialogContent>
          <TextField
            label="Category Name"
            variant="outlined"
            value={editCategory ? editCategory.name : ''}
            onChange={(e) => setEditCategory({ ...editCategory, name: e.target.value })}
            margin="normal"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialogOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleEditCategory} color="primary">
            Update Category
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default BlogCategoriesPage;
