import React from 'react';
import { NavLink } from 'react-router-dom';
import { List, ListItem, ListItemText, Divider, ListItemIcon } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import GroupIcon from '@mui/icons-material/Group';
import SettingsIcon from '@mui/icons-material/Settings';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import BusinessIcon from '@mui/icons-material/Business';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import CategoryIcon from '@mui/icons-material/Category';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import BuildIcon from '@mui/icons-material/Build';
import CarRepairIcon from '@mui/icons-material/CarRepair';
import InfoIcon from '@mui/icons-material/Info';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import ArticleIcon from '@mui/icons-material/Article';

import './Sidebar.css';

const Sidebar = ({ visible, toggleSidebar }) => {
  return (
    <div className={`sidebar ${visible ? 'visible' : ''}`}>
      <List component="nav">
        <ListItem button component={NavLink} to="/" className={({ isActive }) => (isActive ? "active" : "")} onClick={toggleSidebar}>
          <ListItemIcon className="sidebar-icon">
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" className="sidebar-text" />
        </ListItem>
        <Divider />
        <ListItem button component={NavLink} to="/home-page-info" className={({ isActive }) => (isActive ? "active" : "")} onClick={toggleSidebar}>
          <ListItemIcon>
            <InfoIcon />
          </ListItemIcon>
          <ListItemText primary="Home Page Info" className="sidebar-text" />
        </ListItem>
        <Divider />
        <ListItem button component={NavLink} to="/complex-structure" className={({ isActive }) => (isActive ? "active" : "")} onClick={toggleSidebar}>
          <ListItemIcon>
            <InfoIcon />
          </ListItemIcon>
          <ListItemText primary="Complex Structure" className="sidebar-text" />
        </ListItem>
        <Divider />
        <ListItem button component={NavLink} to="/users" className={({ isActive }) => (isActive ? "active" : "")} onClick={toggleSidebar}>
          <ListItemIcon className="sidebar-icon">
            <GroupIcon />
          </ListItemIcon>
          <ListItemText primary="Users" className="sidebar-text" />
        </ListItem>
        <Divider />
        <ListItem button component={NavLink} to="/carlisting" className={({ isActive }) => (isActive ? "active" : "")} onClick={toggleSidebar}>
          <ListItemIcon className="sidebar-icon">
            <DirectionsCarIcon />
          </ListItemIcon>
          <ListItemText primary="Car Listing" className="sidebar-text" />
        </ListItem>
        <Divider />
        <ListItem button component={NavLink} to="/manufacturers" className={({ isActive }) => (isActive ? "active" : "")} onClick={toggleSidebar}>
          <ListItemIcon className="sidebar-icon">
            <BusinessIcon />
          </ListItemIcon>
          <ListItemText primary="Manufacturers" className="sidebar-text" />
        </ListItem>
        <Divider />
        <ListItem button component={NavLink} to="/models" className={({ isActive }) => (isActive ? "active" : "")} onClick={toggleSidebar}>
          <ListItemIcon className="sidebar-icon">
            <AccountTreeIcon />
          </ListItemIcon>
          <ListItemText primary="Models" className="sidebar-text" />
        </ListItem>
        <Divider />
        <ListItem button component={NavLink} to="/body-types" className={({ isActive }) => (isActive ? "active" : "")} onClick={toggleSidebar}>
          <ListItemIcon className="sidebar-icon">
            <CategoryIcon />
          </ListItemIcon>
          <ListItemText primary="Body Types" className="sidebar-text" />
        </ListItem>
        <Divider />
        <ListItem button component={NavLink} to="/partners" className={({ isActive }) => (isActive ? "active" : "")} onClick={toggleSidebar}>
          <ListItemIcon className="sidebar-icon">
            <LocalOfferIcon />
          </ListItemIcon>
          <ListItemText primary="Partners" className="sidebar-text" />
        </ListItem>
        <Divider />
        <ListItem button component={NavLink} to="/owners" className={({ isActive }) => (isActive ? "active" : "")} onClick={toggleSidebar}>
          <ListItemIcon className="sidebar-icon">
            <BuildIcon />
          </ListItemIcon>
          <ListItemText primary="Owners" className="sidebar-text" />
        </ListItem>
        <Divider />
        <ListItem button component={NavLink} to="/services" className={({ isActive }) => (isActive ? "active" : "")} onClick={toggleSidebar}>
          <ListItemIcon className="sidebar-icon">
            <CarRepairIcon />
          </ListItemIcon>
          <ListItemText primary="Services" className="sidebar-text" />
        </ListItem>
        <Divider />
        <ListItem button component={NavLink} to="/sell-your-cars" className={({ isActive }) => (isActive ? "active" : "")} onClick={toggleSidebar}>
          <ListItemIcon>
            <DirectionsCarIcon />
          </ListItemIcon>
          <ListItemText primary="Sell Your Cars" className="sidebar-text" />
        </ListItem>
        <Divider />
        <ListItem button component={NavLink} to="/about-us" className={({ isActive }) => (isActive ? "active" : "")} onClick={toggleSidebar}>
          <ListItemIcon>
            <InfoIcon />
          </ListItemIcon>
          <ListItemText primary="About Us" className="sidebar-text" />
        </ListItem>
        <Divider />
        <ListItem button component={NavLink} to="/contact-us" className={({ isActive }) => (isActive ? "active" : "")} onClick={toggleSidebar}>
          <ListItemIcon>
            <ContactMailIcon />
          </ListItemIcon>
          <ListItemText primary="Contact Us" className="sidebar-text" />
        </ListItem>
        <Divider />
        <ListItem button component={NavLink} to="/blogs" className={({ isActive }) => (isActive ? "active" : "")} onClick={toggleSidebar}>
          <ListItemIcon>
            <ArticleIcon />
          </ListItemIcon>
          <ListItemText primary="Blogs" className="sidebar-text" />
        </ListItem>
        <Divider />
        <ListItem button component={NavLink} to="/blog-categories" className={({ isActive }) => (isActive ? "active" : "")} onClick={toggleSidebar}>
          <ListItemIcon>
            <CategoryIcon />
          </ListItemIcon>
          <ListItemText primary="Blog Categories" className="sidebar-text" />
        </ListItem>
        <Divider />
        <ListItem button component={NavLink} to="/settings" className={({ isActive }) => (isActive ? "active" : "")} onClick={toggleSidebar}>
          <ListItemIcon className="sidebar-icon">
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Settings" className="sidebar-text" />
        </ListItem>
        <Divider />
        <ListItem button component={NavLink} to="/messages" className={({ isActive }) => (isActive ? "active" : "")} onClick={toggleSidebar}>
          <ListItemIcon className="sidebar-icon">
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Messages" className="sidebar-text" />
        </ListItem>
        <Divider />
      </List>
    </div>
  );
};

export default Sidebar;
