import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../../config';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import './Partners.css';

const Partners = () => {
  const [partners, setPartners] = useState([]);
  const [newPartnerName, setNewPartnerName] = useState('');
  const [partnerDialogOpen, setPartnerDialogOpen] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchPartners();
  }, []);

  const fetchPartners = () => {
    axios.get(`${config.baseUrl}/partners`)
      .then(response => {
        setPartners(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the partners!", error);
        setError('Failed to fetch partners. Please try again later.');
      });
  };

  const handleAddPartner = () => {
    const formData = new FormData();
    formData.append('name', newPartnerName);

    axios.post(`${config.baseUrl}/partners`, formData)
      .then(response => {
        fetchPartners();
        setNewPartnerName('');
        setPartnerDialogOpen(false);
      })
      .catch(error => {
        console.error("There was an error adding the partner!", error);
        setError('Failed to add partner. Please try again later.');
      });
  };

  const handleDeletePartner = (id) => {
    axios.delete(`${config.baseUrl}/partners/${id}`)
      .then(response => {
        fetchPartners();
      })
      .catch(error => {
        console.error("There was an error deleting the partner!", error);
        setError('Failed to delete partner. Please try again later.');
      });
  };

  return (
    <div className="partners">
      <h1>Partners</h1>
      <div className="add-partner">
        <Button className="add-partner-button" onClick={() => setPartnerDialogOpen(true)}>
          <AddIcon /> Add Partner
        </Button>
      </div>
      {error && <div className="error-message">{error}</div>}
      <div className="partner-list">
        {partners.map(partner => (
          <div key={partner.id} className="partner-item">
            <p>{partner.name}</p>
            <IconButton onClick={() => handleDeletePartner(partner.id)}>
              <DeleteIcon />
            </IconButton>
          </div>
        ))}
      </div>

      <Dialog open={partnerDialogOpen} onClose={() => setPartnerDialogOpen(false)}>
        <DialogTitle>Add New Partner</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Partner Name"
            type="text"
            fullWidth
            value={newPartnerName}
            onChange={(e) => setNewPartnerName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPartnerDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleAddPartner}>Add</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Partners;
