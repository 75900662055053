import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Typography, Box, Paper, Container } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import styles
import config from '../../config';
import './AboutUs.css';

const AboutUs = () => {
  const [content, setContent] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchAboutUs();
  }, []);

  const fetchAboutUs = () => {
    axios.get(`${config.baseUrl}/about-us`)
      .then(response => {
        setContent(response.data.content);
      })
      .catch(error => {
        console.error("There was an error fetching the about us content!", error);
        setError('Failed to fetch content. Please try again later.');
      });
  };

  const handleSaveContent = () => {
    const payload = { content };

    axios.post(`${config.baseUrl}/about-us`, payload)
      .then(response => {
        alert('Content updated successfully!');
      })
      .catch(error => {
        console.error("There was an error saving the content!", error);
        setError('Failed to save content. Please try again later.');
      });
  };

  return (
    <Container maxWidth="md" className="about-us">
      <Typography variant="h4" component="h1" textAlign="center" marginBottom="20px">
        About Us
      </Typography>
      {error && <Typography variant="body1" color="error" textAlign="center">{error}</Typography>}
      <Paper elevation={3} className="editor-section">
        <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="10px">
          <Typography variant="h6" component="h2">
            Edit About Us Content
          </Typography>
          <Button variant="contained" color="primary" onClick={handleSaveContent} className="save-button">
            Save Content
          </Button>
        </Box>
        <ReactQuill
          value={content}
          onChange={setContent}
          modules={{
            toolbar: [
              [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
              [{size: []}],
              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
              [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
              ['link', 'image', 'video'],
              ['clean']
            ],
          }}
          formats={[
            'header', 'font', 'size',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet', 'indent',
            'link', 'image', 'video'
          ]}
          className="quill-editor"
        />
      </Paper>
    </Container>
  );
};

export default AboutUs;
