import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, TextField, Select, MenuItem, InputLabel, FormControl, FormHelperText, Typography, IconButton, CircularProgress } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from '../../config';
import './EditCar.css';

const EditCar = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [car, setCar] = useState(null);
  const [manufacturers, setManufacturers] = useState([]);
  const [carModels, setCarModels] = useState([]);
  const [bodyTypes, setBodyTypes] = useState([]);
  const [owners, setOwners] = useState([]);
  const [errors, setErrors] = useState({});
  const [previewImage, setPreviewImage] = useState(null);
  const [previewImages, setPreviewImages] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchCarDetails();
    fetchManufacturers();
    fetchBodyTypes();
    fetchOwners();
  }, []);

  const fetchCarDetails = () => {
    axios.get(`${config.baseUrl}/car-listings/${id}`)
      .then(response => {
        setCar({
          ...response.data,
          options: 'Default Options', // Hardcoded value
          features: 'Default Features', // Hardcoded value
          interest_count: "0", // Hardcoded value
        });
        setPreviewImage(`${config.imagebaseUrl}/${response.data.image}`);
        setPreviewImages(response.data.images.map(img => ({
          id: img.id,
          url: `${config.imagebaseUrl}/${img.image_path}`
        })));
        fetchCarModels(response.data.manufacturer_id);
      })
      .catch(error => console.error('Error fetching car details:', error));
  };

  const fetchManufacturers = () => {
    axios.get(`${config.baseUrl}/manufacturers`)
      .then(response => setManufacturers(response.data))
      .catch(error => console.error('Error fetching manufacturers:', error));
  };

  const fetchCarModels = (manufacturerId) => {
    axios.get(`${config.baseUrl}/car-models/manufacturer/${manufacturerId}`)
      .then(response => setCarModels(response.data))
      .catch(error => console.error('Error fetching car models:', error));
  };

  const fetchBodyTypes = () => {
    axios.get(`${config.baseUrl}/body-types`)
      .then(response => setBodyTypes(response.data))
      .catch(error => console.error('Error fetching body types:', error));
  };

  const fetchOwners = () => {
    axios.get(`${config.baseUrl}/owners`)
      .then(response => setOwners(response.data))
      .catch(error => console.error('Error fetching owners:', error));
  };

  const handleInputChange = (e) => {
    setCar({ ...car, [e.target.name]: e.target.value });
  };

  const handleDescriptionChange = (value) => {
    setCar({ ...car, description: value });
  };

  const handlePrimaryImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCar({ ...car, image: file });
      setPreviewImage(URL.createObjectURL(file));
    }
  };

  const handleAdditionalImagesUpload = (e) => {
    const files = Array.from(e.target.files);
    const newImages = [...car.images, ...files];
    setCar({ ...car, images: newImages });

    const imageUrls = newImages.map(file => {
      if (file instanceof File) {
        return URL.createObjectURL(file);
      } else {
        return `${config.imagebaseUrl}/${file.image_path}`;
      }
    });
    setPreviewImages(imageUrls);
  };

  const handleRemoveAdditionalImage = (index, imageId) => {
    if (imageId) {
      axios.delete(`${config.baseUrl}/car-listings/${id}/images/${imageId}`)
        .then(response => {
          const newImages = previewImages.filter((img, idx) => idx !== index);
          setPreviewImages(newImages);
          const carImages = car.images.filter((img, idx) => idx !== index);
          setCar({ ...car, images: carImages });
        })
        .catch(error => console.error('Error deleting image:', error));
    } else {
      const newImages = [...car.images];
      newImages.splice(index, 1);
      setCar({ ...car, images: newImages });

      const imageUrls = newImages.map(file => {
        if (file instanceof File) {
          return URL.createObjectURL(file);
        } else {
          return `${config.imagebaseUrl}/${file.image_path}`;
        }
      });
      setPreviewImages(imageUrls);
    }
  };

  const handleSaveCar = () => {
    const newErrors = {};
    setLoading(true);

    console.log('Car object before validation:', car);

    // Validate required fields
    Object.keys(car).forEach(key => {
      if (!car[key] && key !== 'description' && key !== 'images') {
        newErrors[key] = 'This field is required';
      }
    });

    if (Object.keys(newErrors).length > 0) {
      console.log('Validation errors:', newErrors);
      setErrors(newErrors);
      Object.keys(newErrors).forEach(key => {
        toast.error(`${key.charAt(0).toUpperCase() + key.slice(1)}: ${newErrors[key]}`);
      });
      setLoading(false);
      return;
    }

    console.log('Form data is valid. Proceeding to save car...');

    const formData = new FormData();
    Object.keys(car).forEach(key => {
      if (key === 'image') {
        if (car.image instanceof File) {
          formData.append(key, car[key], car[key].name);
        }
      } else if (key === 'images') {
        // Images will be handled separately in `saveAdditionalImages`
        console.log('Skipping images in initial formData');
      } else {
        formData.append(key, car[key]);
      }
    });

    axios.post(`${config.baseUrl}/car-listings/update/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(response => {
        console.log('Response from backend:', response.data);
        saveAdditionalImages();
      })
      .catch(error => {
        console.error('Error saving car listing:', error.response?.data || error.message);
        setLoading(false);
      });
  };

  const saveAdditionalImages = () => {
    console.log('Saving additional images...');

    const additionalImagesFormData = new FormData();
    car.images.forEach(image => {
      if (image instanceof File) {
        additionalImagesFormData.append('images[]', image);
      }
    });

    if (additionalImagesFormData.has('images[]')) {
      axios.post(`${config.baseUrl}/car-listings/${id}/images`, additionalImagesFormData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(response => {
          console.log('Additional images saved:', response.data);
          navigate('/carlisting');
          setLoading(false);
        })
        .catch(error => {
          console.error('Error saving additional images:', error.response?.data || error.message);
          setLoading(false);
        });
    } else {
      console.log('No additional images to save.');
      setLoading(false);
      navigate('/carlisting');
    }
  };

  if (!car) return <Typography>Loading...</Typography>;

  return (
    <div className="edit-car-container">
      <ToastContainer />
      <Typography variant="h4" className="edit-car-title">Edit Car</Typography>
      <div className="edit-car-form">
        <TextField
          label="Title"
          name="title"
          variant="outlined"
          value={car.title}
          onChange={handleInputChange}
          margin="normal"
          fullWidth
          error={!!errors.title}
          helperText={errors.title}
          className="full-width-text-field"
        />
        <TextField
          label="Year"
          name="year"
          variant="outlined"
          value={car.year}
          onChange={handleInputChange}
          margin="normal"
          fullWidth
          error={!!errors.year}
          helperText={errors.year}
          className="full-width-text-field"
        />
        <FormControl fullWidth margin="normal" error={!!errors.gear_type} className="full-width-text-field">
          <InputLabel>Gear Type</InputLabel>
          <Select
            name="gear_type"
            value={car.gear_type}
            onChange={handleInputChange}
          >
            <MenuItem value="Automatic">Automatic</MenuItem>
            <MenuItem value="Manual">Manual</MenuItem>
          </Select>
          {errors.gear_type && <FormHelperText>{errors.gear_type}</FormHelperText>}
        </FormControl>
        <FormControl fullWidth margin="normal" error={!!errors.fuel_type} className="full-width-text-field">
          <InputLabel>Fuel Type</InputLabel>
          <Select
            name="fuel_type"
            value={car.fuel_type}
            onChange={handleInputChange}
          >
            <MenuItem value="Petrol">Petrol</MenuItem>
            <MenuItem value="Diesel">Diesel</MenuItem>
            <MenuItem value="Electric">Electric</MenuItem>
            <MenuItem value="Hybrid">Hybrid</MenuItem>
            <MenuItem value="CNG">CNG (Compressed Natural Gas)</MenuItem>
            <MenuItem value="LPG">LPG (Liquefied Petroleum Gas)</MenuItem>
            <MenuItem value="Hydrogen">Hydrogen</MenuItem>
          </Select>
          {errors.fuel_type && <FormHelperText>{errors.fuel_type}</FormHelperText>}
        </FormControl>
        <FormControl fullWidth margin="normal" error={!!errors.manufacturer_id} className="full-width-text-field">
          <InputLabel>Manufacturer</InputLabel>
          <Select
            name="manufacturer_id"
            value={car.manufacturer_id}
            onChange={(e) => {
              handleInputChange(e);
              fetchCarModels(e.target.value);
            }}
          >
            {manufacturers.map(manufacturer => (
              <MenuItem key={manufacturer.id} value={manufacturer.id}>
                {manufacturer.name}
              </MenuItem>
            ))}
          </Select>
          {errors.manufacturer_id && <FormHelperText>{errors.manufacturer_id}</FormHelperText>}
        </FormControl>
        <FormControl fullWidth margin="normal" error={!!errors.car_model_id} className="full-width-text-field">
          <InputLabel>Car Model</InputLabel>
          <Select
            name="car_model_id"
            value={car.car_model_id}
            onChange={handleInputChange}
          >
            {carModels.map(carModel => (
              <MenuItem key={carModel.id} value={carModel.id}>
                {carModel.name}
              </MenuItem>
            ))}
          </Select>
          {errors.car_model_id && <FormHelperText>{errors.car_model_id}</FormHelperText>}
        </FormControl>
        <FormControl fullWidth margin="normal" error={!!errors.body_type_id} className="full-width-text-field">
          <InputLabel>Body Type</InputLabel>
          <Select
            name="body_type_id"
            value={car.body_type_id}
            onChange={handleInputChange}
          >
            {bodyTypes.map(bodyType => (
              <MenuItem key={bodyType.id} value={bodyType.id}>
                {bodyType.type}
              </MenuItem>
            ))}
          </Select>
          {errors.body_type_id && <FormHelperText>{errors.body_type_id}</FormHelperText>}
        </FormControl>
        <FormControl fullWidth margin="normal" error={!!errors.owner_id} className="full-width-text-field">
          <InputLabel>Owner</InputLabel>
          <Select
            name="owner_id"
            value={car.owner_id}
            onChange={handleInputChange}
          >
            {owners.map(owner => (
              <MenuItem key={owner.id} value={owner.id}>
                {owner.name}
              </MenuItem>
            ))}
          </Select>
          {errors.owner_id && <FormHelperText>{errors.owner_id}</FormHelperText>}
        </FormControl>
        <TextField
          label="Mileage"
          name="mileage"
          variant="outlined"
          value={car.mileage}
          onChange={handleInputChange}
          margin="normal"
          fullWidth
          error={!!errors.mileage}
          helperText={errors.mileage}
          className="full-width-text-field"
        />
        <TextField
          label="Price"
          name="price"
          variant="outlined"
          value={car.price}
          onChange={handleInputChange}
          margin="normal"
          fullWidth
          error={!!errors.price}
          helperText={errors.price}
          className="full-width-text-field"
        />
        <TextField
          label="Phone"
          name="phone"
          variant="outlined"
          value={car.phone}
          onChange={handleInputChange}
          margin="normal"
          fullWidth
          error={!!errors.phone}
          helperText={errors.phone}
          className="full-width-text-field"
        />
        <TextField
          label="Service Contact"
          name="service_contact"
          variant="outlined"
          value={car.service_contact}
          onChange={handleInputChange}
          margin="normal"
          fullWidth
          error={!!errors.service_contact}
          helperText={errors.service_contact}
          className="full-width-text-field"
        />
        <TextField
          label="Engine Size"
          name="engine_size"
          variant="outlined"
          value={car.engine_size}
          onChange={handleInputChange}
          margin="normal"
          fullWidth
          error={!!errors.engine_size}
          helperText={errors.engine_size}
          className="full-width-text-field"
        />
        <div className="editor-container">
          <Typography variant="body2">Description:</Typography>
          <ReactQuill
            value={car.description}
            onChange={handleDescriptionChange}
            modules={{
              toolbar: [
                [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                [{ size: [] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                ['link', 'image', 'video'],
                ['clean']
              ],
            }}
            formats={[
              'header', 'font', 'size',
              'bold', 'italic', 'underline', 'strike', 'blockquote',
              'list', 'bullet', 'indent',
              'link', 'image', 'video'
            ]}
            className="quill-editor"
          />
        </div>
        <TextField
          label="Instagram Post URL"
          name="instagram_post"
          variant="outlined"
          value={car.instagram_post}
          onChange={handleInputChange}
          margin="normal"
          fullWidth
          error={!!errors.instagram_post}
          helperText={errors.instagram_post}
          className="full-width-text-field"
        />
        <TextField
          label="Warranty"
          name="warranty"
          variant="outlined"
          value={car.warranty}
          onChange={handleInputChange}
          margin="normal"
          fullWidth
          error={!!errors.warranty}
          helperText={errors.warranty}
          className="full-width-text-field"
        />
        <TextField
          label="Price Per Month"
          name="price_per_month"
          variant="outlined"
          value={car.price_per_month}
          onChange={handleInputChange}
          margin="normal"
          fullWidth
          error={!!errors.price_per_month}
          helperText={errors.price_per_month}
          className="full-width-text-field"
        />
        <div className="image-upload-section dashed-box">
          {previewImage && (
            <div>
              <Typography variant="body2">Primary Image:</Typography>
              <div className="preview-image-container">
                <img src={previewImage} alt="Primary Preview" className="preview-image" />
              </div>
            </div>
          )}
          <input
            type="file"
            accept="image/*"
            onChange={handlePrimaryImageUpload}
            style={{ display: 'none' }}
            id="upload-primary-image"
          />
          <label htmlFor="upload-primary-image" className="upload-label">
            <Button component="span" variant="contained" color="primary">
              Upload Primary Image
            </Button>
          </label>
        </div>
        <div className="image-upload-section dashed-box">
          <Typography variant="body2">Additional Images:</Typography>
          {previewImages.length > 0 ? (
            previewImages.map((preview, index) => (
              <div key={index} className="preview-image-container">
                <img src={preview.url || preview} alt={`Preview ${index}`} className="preview-image" />
                <IconButton 
                  color="secondary" 
                  onClick={() => handleRemoveAdditionalImage(index, preview.id)}
                  className="icon-button"
                >
                  <DeleteIcon fontSize="large" />
                </IconButton>
              </div>
            ))
          ) : (
            <div className="upload-placeholder">No images selected</div>
          )}
          <input
            type="file"
            accept="image/*"
            multiple
            onChange={handleAdditionalImagesUpload}
            style={{ display: 'none' }}
            id="upload-car-images"
          />
          <label htmlFor="upload-car-images" className="upload-label">
            <Button component="span" variant="contained" color="primary">
              Upload Additional Images
            </Button>
          </label>
        </div>

        <div className="edit-car-actions">
          <Button onClick={() => navigate('/carlisting')} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSaveCar} color="primary">
            Save Changes
          </Button>
        </div>
      </div>
      {loading && (
        <div className="loading-overlay">
          <CircularProgress color="inherit" />
        </div>
      )}
    </div>
  );
};

export default EditCar;
