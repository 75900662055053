import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, IconButton ,CircularProgress} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import styles
import config from '../../config';
import './Services.css';

const Services = () => {
  const [services, setServices] = useState([]);
  const [newService, setNewService] = useState({ title: '', description: '', body: '', image: null });
  const [serviceDialogOpen, setServiceDialogOpen] = useState(false);
  const [editingServiceId, setEditingServiceId] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false); // Added state for loading

  useEffect(() => {
    fetchServices();
  }, []);

  const fetchServices = () => {
    axios.get(`${config.baseUrl}/services`)
      .then(response => {
        setServices(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the services!", error);
        setError('Failed to fetch services. Please try again later.');
      });
  };

  const handleAddService = () => {
    const formData = new FormData();
    formData.append('title', newService.title);
    formData.append('description', newService.description);
    formData.append('body', newService.body);
    if (newService.image) {
      formData.append('image', newService.image);
    }

    setLoading(true);
    axios.post(`${config.baseUrl}/services`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(response => {
        fetchServices();
        setNewService({ title: '', description: '', body: '', image: null });
        setServiceDialogOpen(false);
        setLoading(false);
      })
      .catch(error => {
        console.error("There was an error adding the service!", error);
        setError('Failed to add service. Please try again later.');
        setLoading(false);
      });
  };

  const handleDeleteService = (id) => {
    setLoading(true);
    axios.delete(`${config.baseUrl}/services/${id}`)
      .then(response => {
        fetchServices();
        setLoading(false);
      })
      .catch(error => {
        console.error("There was an error deleting the service!", error);
        setError('Failed to delete service. Please try again later.');
        setLoading(false);
      });
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length > 0) {
      setNewService({ ...newService, image: files[0] });
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  return (
    <div className="services">
      <h1>Services</h1>
      <div className="add-service">
        <Button className="add-service-button" onClick={() => setServiceDialogOpen(true)}>
          <AddIcon /> Add Service
        </Button>
      </div>
      {error && <div className="error-message">{error}</div>}
      <div className="service-list">
        {services.map(service => (
          <div key={service.id} className="service-item">
            <img src={`${config.imagebaseUrl}/${service.image}`} alt={service.title} />
            <div className="service-info">
              <h2>{service.title}</h2>
              <p>{service.description}</p>
              <IconButton onClick={() => handleDeleteService(service.id)}>
                <DeleteIcon />
              </IconButton>
            </div>
          </div>
        ))}
      </div>

      <Dialog open={serviceDialogOpen} onClose={() => setServiceDialogOpen(false)} fullWidth maxWidth="md">
        <DialogTitle>{editingServiceId ? 'Edit Service' : 'Add New Service'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Service Title"
            type="text"
            fullWidth
            value={newService.title}
            onChange={(e) => setNewService({ ...newService, title: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Service Description"
            type="text"
            fullWidth
            value={newService.description}
            onChange={(e) => setNewService({ ...newService, description: e.target.value })}
          />
          <ReactQuill
            value={newService.body}
            onChange={(value) => setNewService({ ...newService, body: value })}
            modules={{
              toolbar: [
                [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
                [{size: []}],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                ['link', 'image', 'video'],
                ['clean']
              ],
            }}
            formats={[
              'header', 'font', 'size',
              'bold', 'italic', 'underline', 'strike', 'blockquote',
              'list', 'bullet', 'indent',
              'link', 'image', 'video'
            ]}
            className="quill-editor"
          />
          <div
            className="drag-drop-box"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >
            {newService.image ? (
              <img src={URL.createObjectURL(newService.image)} alt="Preview" className="preview-image" />
            ) : (
              <div className="upload-icon">
                <p>Drag and drop an image here, or click to select one</p>
              </div>
            )}
            <input
              type="file"
              onChange={(e) => setNewService({ ...newService, image: e.target.files[0] })}
              className="upload-label"
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setServiceDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleAddService}>{editingServiceId ? 'Update' : 'Add'}</Button>
        </DialogActions>
      </Dialog>

      {loading && (
        <div className="loading-overlay">
          <CircularProgress color="inherit" />
        </div>
      )}
    </div>
  );
};

export default Services;
