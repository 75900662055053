import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, CardActions, Button, Grid } from '@mui/material';
import axios from 'axios';
import './Messages.css';

const AdminMessages = () => {
  const [messages, setMessages] = useState([]);
  const [showMore, setShowMore] = useState({});

  useEffect(() => {
    axios.get('https://wcp.symloop.com/api/inquiries')
      .then(response => {
        setMessages(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the inquiries!', error);
      });
  }, []);

  const handleShowMore = (id) => {
    setShowMore((prevState) => ({ ...prevState, [id]: !prevState[id] }));
  };

  const handleDelete = (id) => {
    axios.delete(`https://wcp.symloop.com/api/inquiries/${id}`)
      .then(response => {
        setMessages(messages.filter(message => message.id !== id));
      })
      .catch(error => {
        console.error('There was an error deleting the inquiry!', error);
      });
  };

  return (
    <Grid container spacing={2}>
      {messages.map((message) => (
        <Grid item xs={12} sm={6} md={4} key={message.id}>
          <Card className={`card ${showMore[message.id] ? 'show-more' : ''}`}>
            <CardContent className="card-content">
              <Typography variant="h6" className="card-title">{message.name}</Typography>
              <Typography className="card-email">{message.email}</Typography>
              <Typography className="card-phone">{message.phone_number}</Typography>
              <Typography 
                variant="body2" 
                component="p" 
                className={`card-message ${showMore[message.id] ? 'show-more' : ''}`}
              >
                {message.message}
              </Typography>
            </CardContent>
            <CardActions className="card-actions">
              <Button 
                size="small" 
                style={{ flex: 1, margin: '0 5px', backgroundColor: '#2196f3', color: 'white' }}
                onClick={() => handleShowMore(message.id)}
              >
                {showMore[message.id] ? 'Show Less' : 'Show More'}
              </Button>
              <Button 
                size="small" 
                style={{ flex: 1, margin: '0 5px', backgroundColor: '#4caf50', color: 'white' }}
                href={`https://barugzaimotors.com/car-details/${message.car_listing_id}`}
                target="_blank"
              >
                Check Vehicle
              </Button>
              <Button 
                size="small" 
                style={{ flex: 1, margin: '0 5px', backgroundColor: '#f44336', color: 'white' }}
                onClick={() => handleDelete(message.id)}
              >
                Delete This
              </Button>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default AdminMessages;
