import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../../config';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import './Owners.css';

const Owners = () => {
  const [globalOwner, setGlobalOwner] = useState({});
  const [owners, setOwners] = useState([]);
  const [newOwner, setNewOwner] = useState({ name: '', email: '', phone_number: '' });
  const [ownerDialogOpen, setOwnerDialogOpen] = useState(false);
  const [editingOwnerId, setEditingOwnerId] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchGlobalOwner();
    fetchOwners();
  }, []);

  const fetchGlobalOwner = () => {
    axios.get(`${config.baseUrl}/global-owner`)
      .then(response => {
        setGlobalOwner(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the global owner!", error);
        setError('Failed to fetch global owner. Please try again later.');
      });
  };

  const updateGlobalOwner = () => {
    axios.put(`${config.baseUrl}/global-owner`, globalOwner)
      .then(response => {
        setGlobalOwner(response.data);
        alert('Global owner updated successfully');
      })
      .catch(error => {
        console.error("There was an error updating the global owner!", error);
        setError('Failed to update global owner. Please try again later.');
      });
  };

  const fetchOwners = () => {
    axios.get(`${config.baseUrl}/owners`)
      .then(response => {
        setOwners(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the owners!", error);
        setError('Failed to fetch owners. Please try again later.');
      });
  };

  const handleAddOwner = () => {
    if (editingOwnerId) {
      axios.put(`${config.baseUrl}/owners/${editingOwnerId}`, newOwner)
        .then(response => {
          fetchOwners();
          setNewOwner({ name: '', email: '', phone_number: '' });
          setOwnerDialogOpen(false);
          setEditingOwnerId(null);
        })
        .catch(error => {
          console.error("There was an error updating the owner!", error);
          setError('Failed to update owner. Please try again later.');
        });
    } else {
      axios.post(`${config.baseUrl}/owners`, newOwner)
        .then(response => {
          fetchOwners();
          setNewOwner({ name: '', email: '', phone_number: '' });
          setOwnerDialogOpen(false);
        })
        .catch(error => {
          console.error("There was an error adding the owner!", error);
          setError('Failed to add owner. Please try again later.');
        });
    }
  };

  const handleDeleteOwner = (id) => {
    axios.delete(`${config.baseUrl}/owners/${id}`)
      .then(response => {
        fetchOwners();
      })
      .catch(error => {
        console.error("There was an error deleting the owner!", error);
        setError('Failed to delete owner. Please try again later.');
      });
  };

  const handleEditOwner = (owner) => {
    setNewOwner(owner);
    setEditingOwnerId(owner.id);
    setOwnerDialogOpen(true);
  };

  const setOwnerAsGlobal = (owner) => {
    setGlobalOwner({
      name: owner.name,
      email: owner.email,
      phone: owner.phone_number
    });
  };

  return (
    <div className="owners">
      <h1>Owners</h1>
      <div className="global-owner">
        <h2>Global Owner</h2>
        <TextField
          label="Name"
          value={globalOwner.name || ''}
          onChange={(e) => setGlobalOwner({ ...globalOwner, name: e.target.value })}
          fullWidth
          className="text-field"
        />
        <TextField
          label="Email"
          value={globalOwner.email || ''}
          onChange={(e) => setGlobalOwner({ ...globalOwner, email: e.target.value })}
          fullWidth
          className="text-field"
        />
        <TextField
          label="Phone"
          value={globalOwner.phone || ''}
          onChange={(e) => setGlobalOwner({ ...globalOwner, phone: e.target.value })}
          fullWidth
          className="text-field"
        />
        <Button onClick={updateGlobalOwner} className="update-global-owner-button">
          Update Global Owner
        </Button>
      </div>
      <div className="add-owner">
        <Button className="add-owner-button" onClick={() => setOwnerDialogOpen(true)}>
          <AddIcon /> Add Owner
        </Button>
      </div>
      {error && <div className="error-message">{error}</div>}
      <div className="owner-list">
        {owners.map(owner => (
          <div key={owner.id} className="owner-item">
            <p>{owner.name}</p>
            <IconButton onClick={() => handleEditOwner(owner)}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => handleDeleteOwner(owner.id)}>
              <DeleteIcon />
            </IconButton>
            <Button onClick={() => setOwnerAsGlobal(owner)} className="set-global-owner-button">
              Set as Global
            </Button>
          </div>
        ))}
      </div>

      <Dialog open={ownerDialogOpen} onClose={() => setOwnerDialogOpen(false)}>
        <DialogTitle>{editingOwnerId ? 'Edit Owner' : 'Add New Owner'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Owner Name"
            type="text"
            fullWidth
            value={newOwner.name}
            onChange={(e) => setNewOwner({ ...newOwner, name: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Owner Email"
            type="email"
            fullWidth
            value={newOwner.email}
            onChange={(e) => setNewOwner({ ...newOwner, email: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Owner Phone"
            type="text"
            fullWidth
            value={newOwner.phone_number}
            onChange={(e) => setNewOwner({ ...newOwner, phone_number: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOwnerDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleAddOwner}>{editingOwnerId ? 'Update' : 'Add'}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Owners;
