import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../../config';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, IconButton, Typography, Box, Paper } from '@mui/material';
import { CloudUpload as CloudUploadIcon, Delete as DeleteIcon } from '@mui/icons-material';
import './HomePageInformation.css';

const HomePageInformation = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [title, setTitle] = useState('');
  const [logo, setLogo] = useState(null);
  const [newImages, setNewImages] = useState([]);
  const [previewLogo, setPreviewLogo] = useState(null);
  const [previewImages, setPreviewImages] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [instagramPosts, setInstagramPosts] = useState([]);
  const [instagramError, setInstagramError] = useState(null);

  useEffect(() => {
    fetchData();
    fetchInstagramPosts();
  }, []);

  const fetchData = () => {
    axios.get(`${config.baseUrl}/first-sections/1`)
      .then(response => {
        setData(response.data);
        setTitle(response.data.title);
      })
      .catch(error => {
        console.error("There was an error fetching the data!", error);
        setError('Failed to fetch data. Please try again later.');
      });
  };

  const fetchInstagramPosts = () => {
    axios.get(`${config.baseUrl}/instagrams`)
      .then(response => {
        setInstagramPosts(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the Instagram posts!", error);
        setInstagramError('Failed to fetch Instagram posts. Please try again later.');
      });
  };

  const handleAddImages = () => {
    const formData = new FormData();
    newImages.forEach(image => formData.append('images[]', image));

    axios.post(`${config.baseUrl}/first-section/1/images`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {
      fetchData();
      setNewImages([]);
      setPreviewImages([]);
      setDialogOpen(false);
    })
    .catch(error => {
      console.error("There was an error adding the images!", error);
      setError('Failed to add images. Please try again later.');
    });
  };

  const handleUpdateInfo = () => {
    const formData = new FormData();
    formData.append('title', title);
    if (logo) {
      formData.append('logo', logo);
    }

    axios.post(`${config.baseUrl}/first-sections`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {
      fetchData();
      setLogo(null);
      setPreviewLogo(null);
    })
    .catch(error => {
      console.error("There was an error updating the information!", error);
      setError('Failed to update information. Please try again later.');
    });
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setLogo(file);
    setPreviewLogo(URL.createObjectURL(file));
  };

  const handleNewImagesUpload = (e) => {
    const files = Array.from(e.target.files);
    setNewImages(files);
    setPreviewImages(files.map(file => URL.createObjectURL(file)));
  };

  const handleDeleteImage = (imageId) => {
    axios.delete(`${config.baseUrl}/first-section/1/images/${imageId}`)
      .then(response => {
        fetchData();
      })
      .catch(error => {
        console.error("There was an error deleting the image!", error);
        setError('Failed to delete image. Please try again later.');
      });
  };

  const handleUpdateInstagramPosts = () => {
    Promise.all(instagramPosts.map(post => 
      axios.put(`${config.baseUrl}/instagram/${post.id}`, post)
    ))
      .then(() => {
        fetchInstagramPosts();
      })
      .catch(error => {
        console.error("There was an error updating the Instagram posts!", error);
        setInstagramError('Failed to update Instagram posts. Please try again later.');
      });
  };

  const handleInputChange = (index, field, value) => {
    const updatedPosts = [...instagramPosts];
    updatedPosts[index][field] = value;
    setInstagramPosts(updatedPosts);
  };

  return (
    <Box className="home-page-information" sx={{ padding: '20px', maxWidth: '1000px', margin: '0 auto', backgroundColor: '#f0f2f5', borderRadius: '10px', boxShadow: 3 }}>
      <Typography variant="h4" component="h1" textAlign="center" marginBottom="20px" color="#333">
        Home Page Information
      </Typography>
      {error && <Typography variant="body1" color="error" textAlign="center">{error}</Typography>}
      {data && (
        <Paper elevation={3} sx={{ padding: '20px', marginBottom: '20px' }}>
          <Typography variant="h6" component="h2" marginBottom="10px">
            Update Information
          </Typography>
          <TextField
            label="Title"
            variant="outlined"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            margin="normal"
            fullWidth
            sx={{ marginBottom: '20px' }}
          />
          <Box display="flex" alignItems="center" marginBottom="20px">
            <img src={previewLogo || `${config.imagebaseUrl}/${data.logo}`} alt="Logo" className="logo-preview" />
            <input
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              style={{ display: 'none' }}
              id="upload-logo-button"
            />
            <label htmlFor="upload-logo-button">
              <IconButton component="span">
                <CloudUploadIcon />
              </IconButton>
            </label>
          </Box>
          <Button variant="contained" color="primary" onClick={handleUpdateInfo} sx={{ backgroundColor: '#000', '&:hover': { backgroundColor: '#e67e22' } }}>
            Update Information
          </Button>
        </Paper>
      )}
      <Paper elevation={3} sx={{ padding: '20px' }}>
        <Typography variant="h6" component="h2" marginBottom="10px">
          Slider Images
        </Typography>
        <Typography variant="body2" color="textSecondary" marginBottom="20px">
          Add, view, and delete slider images:
        </Typography>
        <Box display="grid" gridTemplateColumns="repeat(auto-fill, minmax(200px, 1fr))" gap="10px" marginBottom="20px">
          {data && data.images.map(image => (
            <Box key={image.id} position="relative">
              <img src={`${config.imagebaseUrl}/${image.image_path}`} alt="Slider" className="slider-image" />
              <IconButton className="delete-button" onClick={() => handleDeleteImage(image.id)} sx={{ position: 'absolute', top: '10px', right: '10px', backgroundColor: 'rgba(255, 255, 255, 0.8)', borderRadius: '50%' }}>
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}
        </Box>
        <Button variant="contained" color="primary" onClick={() => setDialogOpen(true)} sx={{ backgroundColor: '#000', '&:hover': { backgroundColor: '#e67e22' }, marginBottom: '10px' }}>
          Add Images to Slider
        </Button>
      </Paper>

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Add Images to Slider</DialogTitle>
        <DialogContent>
          <input
            type="file"
            accept="image/*"
            multiple
            onChange={handleNewImagesUpload}
            style={{ display: 'none' }}
            id="upload-slider-images"
          />
          <label htmlFor="upload-slider-images" className="upload-label">
            <IconButton component="span">
              <CloudUploadIcon />
            </IconButton>
            <Typography variant="body2">Drag & Drop images here or click to upload</Typography>
          </label>
          <Box display="grid" gridTemplateColumns="repeat(auto-fill, minmax(150px, 1fr))" gap="10px" marginTop="10px">
            {previewImages.map((src, index) => (
              <Box key={index} position="relative">
                <img src={src} alt={`Preview ${index}`} className="preview-slider-image" />
              </Box>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleAddImages} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>

      <SecondSection />
      <InstagramSection instagramPosts={instagramPosts} setInstagramPosts={setInstagramPosts} handleUpdateInstagramPosts={handleUpdateInstagramPosts} />
    </Box>
  );
};

const SecondSection = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [mainTitle, setMainTitle] = useState('');
  const [details, setDetails] = useState([{ title: '', body: '' }, { title: '', body: '' }, { title: '', body: '' }]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    axios.get(`${config.baseUrl}/second-sections/1`)
      .then(response => {
        setData(response.data);
        setMainTitle(response.data.main_title);
        setDetails(response.data.details);
      })
      .catch(error => {
        console.error("There was an error fetching the data!", error);
        setError('Failed to fetch data. Please try again later.');
      });
  };

  const handleUpdateInfo = () => {
    const updatedDetails = details.map(detail => ({ title: detail.title, body: detail.body }));

    const payload = {
      main_title: mainTitle,
      details: updatedDetails,
    };

    axios.post(`${config.baseUrl}/second-sections`, payload)
      .then(response => {
        fetchData();
      })
      .catch(error => {
        console.error("There was an error updating the information!", error);
        setError('Failed to update information. Please try again later.');
      });
  };

  return (
    <Box sx={{ padding: '20px', marginTop: '20px', backgroundColor: '#f0f2f5', borderRadius: '10px', boxShadow: 3 }}>
      <Typography variant="h4" component="h1" textAlign="center" marginBottom="20px" color="#333">
        Second Section Information
      </Typography>
      {error && <Typography variant="body1" color="error" textAlign="center">{error}</Typography>}
      {data && (
        <Paper elevation={3} sx={{ padding: '20px' }}>
          <TextField
            label="Main Title"
            variant="outlined"
            value={mainTitle}
            onChange={(e) => setMainTitle(e.target.value)}
            margin="normal"
            fullWidth
            sx={{ marginBottom: '20px' }}
          />
          {details.map((detail, index) => (
            <Box key={index} sx={{ marginBottom: '20px' }}>
              <TextField
                label="Title"
                variant="outlined"
                value={detail.title}
                onChange={(e) => {
                  const newDetails = [...details];
                  newDetails[index].title = e.target.value;
                  setDetails(newDetails);
                }}
                margin="normal"
                fullWidth
                sx={{ marginBottom: '10px' }}
              />
              <TextField
                label="Body"
                variant="outlined"
                value={detail.body}
                onChange={(e) => {
                  const newDetails = [...details];
                  newDetails[index].body = e.target.value;
                  setDetails(newDetails);
                }}
                margin="normal"
                fullWidth
                multiline
                rows={4}
              />
            </Box>
          ))}
          <Button variant="contained" color="primary" onClick={handleUpdateInfo} sx={{ backgroundColor: '#000', '&:hover': { backgroundColor: '#e67e22' } }}>
            Update Information
          </Button>
        </Paper>
      )}
    </Box>
  );
};

const InstagramSection = ({ instagramPosts, setInstagramPosts, handleUpdateInstagramPosts }) => {
  const handleInputChange = (index, field, value) => {
    const updatedPosts = [...instagramPosts];
    updatedPosts[index][field] = value;
    setInstagramPosts(updatedPosts);
  };

  return (
    <Box sx={{ padding: '20px', marginTop: '20px', backgroundColor: '#f0f2f5', borderRadius: '10px', boxShadow: 3 }}>
      <Typography variant="h4" component="h1" textAlign="center" marginBottom="20px" color="#333">
        Instagram Section
      </Typography>
      {instagramPosts.map((post, index) => (
        <Paper key={post.id} elevation={3} sx={{ padding: '20px', marginBottom: '20px' }}>
          <TextField
            label="Image URL"
            variant="outlined"
            value={post.image}
            onChange={(e) => handleInputChange(index, 'image', e.target.value)}
            margin="normal"
            fullWidth
            sx={{ marginBottom: '10px' }}
          />
          <TextField
            label="Instagram Link"
            variant="outlined"
            value={post.link}
            onChange={(e) => handleInputChange(index, 'link', e.target.value)}
            margin="normal"
            fullWidth
            sx={{ marginBottom: '20px' }}
          />
        </Paper>
      ))}
      <Button variant="contained" color="primary" onClick={handleUpdateInstagramPosts} sx={{ backgroundColor: '#000', '&:hover': { backgroundColor: '#e67e22' } }}>
        Save Instagram Posts
      </Button>
    </Box>
  );
};

export default HomePageInformation;
