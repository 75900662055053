// src/pages/ContactUs/ContactUs.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Typography, Box, Paper, TextField, IconButton } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import config from '../../config';
import './ContactUs.css';

const ContactUs = () => {
  const [contactInfo, setContactInfo] = useState({
    phone: '',
    email: '',
    our_location: '',
    our_opening_hours: '',
    post_description_title: '',
    post_description: '',
  });
  const [interestedUsers, setInterestedUsers] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchContactInfo();
    fetchInterestedUsers();
  }, []);

  const fetchContactInfo = () => {
    axios.get(`${config.baseUrl}/company-details/1`)
      .then(response => {
        setContactInfo(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the contact information!", error);
        setError('Failed to fetch contact information. Please try again later.');
      });
  };

  const fetchInterestedUsers = () => {
    axios.get(`${config.baseUrl}/interested-users`)
      .then(response => {
        setInterestedUsers(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the interested users!", error);
        setError('Failed to fetch interested users. Please try again later.');
      });
  };

  const handleSaveContactInfo = () => {
    axios.post(`${config.baseUrl}/company-details/update`, contactInfo)
      .then(response => {
        alert('Contact information updated successfully!');
      })
      .catch(error => {
        console.error("There was an error saving the contact information!", error);
        setError('Failed to save contact information. Please try again later.');
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContactInfo(prevInfo => ({
      ...prevInfo,
      [name]: value
    }));
  };

  const handleDeleteUser = (id) => {
    axios.delete(`${config.baseUrl}/interested-users/${id}`)
      .then(response => {
        fetchInterestedUsers();
      })
      .catch(error => {
        console.error("There was an error deleting the user!", error);
        setError('Failed to delete user. Please try again later.');
      });
  };

  return (
    <Box className="contact-us">
      <Typography variant="h4" component="h1" textAlign="center" marginBottom="20px">
        Contact Us
      </Typography>
      {error && <Typography variant="body1" color="error" textAlign="center">{error}</Typography>}
      <Paper elevation={3} className="contact-form-section">
        <Typography variant="h6" component="h2" marginBottom="10px">
          Edit Contact Information
        </Typography>
        <TextField
          label="Phone"
          name="phone"
          variant="outlined"
          value={contactInfo.phone}
          onChange={handleChange}
          margin="normal"
          fullWidth
        />
        <TextField
          label="Email"
          name="email"
          variant="outlined"
          value={contactInfo.email}
          onChange={handleChange}
          margin="normal"
          fullWidth
        />
        <TextField
          label="Location"
          name="our_location"
          variant="outlined"
          value={contactInfo.our_location}
          onChange={handleChange}
          margin="normal"
          fullWidth
        />
        <TextField
          label="Opening Hours"
          name="our_opening_hours"
          variant="outlined"
          value={contactInfo.our_opening_hours}
          onChange={handleChange}
          margin="normal"
          fullWidth
        />
        <TextField
          label="Post Description Title"
          name="post_description_title"
          variant="outlined"
          value={contactInfo.post_description_title}
          onChange={handleChange}
          margin="normal"
          fullWidth
        />
        <TextField
          label="Post Description"
          name="post_description"
          variant="outlined"
          value={contactInfo.post_description}
          onChange={handleChange}
          margin="normal"
          fullWidth
          multiline
          rows={4}
        />
        <Button variant="contained" color="primary" onClick={handleSaveContactInfo} className="save-button">
          Save Contact Information
        </Button>
      </Paper>

      <Box className="interested-users-section">
        <Typography variant="h5" component="h2" textAlign="center" marginTop="20px">
          Interested Users
        </Typography>
        {interestedUsers.map(user => (
          <Paper key={user.id} elevation={3} className="user-item">
            <Typography variant="h6">{user.first_name} {user.last_name}</Typography>
            <Typography variant="body1"><strong>Email:</strong> {user.email}</Typography>
            <Typography variant="body1"><strong>Mobile:</strong> {user.mobile}</Typography>
            <Typography variant="body1"><strong>Message:</strong> {user.message}</Typography>
            <IconButton className="delete-button" onClick={() => handleDeleteUser(user.id)}>
              <DeleteIcon />
            </IconButton>
          </Paper>
        ))}
      </Box>
    </Box>
  );
};

export default ContactUs;
