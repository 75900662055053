import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../../config';
import { TextField, Button } from '@mui/material';
import './ComplexStructure.css';

const ComplexStructure = () => {
  const [data, setData] = useState({
    big_title: '',
    secondary_title_1: '',
    secondary_title_2: '',
    secondary_title_3: '',
    body_1: '',
    body_2: '',
    body_3: '',
    value_1: '',
    value_1_text: '',
    value_2: '',
    value_2_text: '',
    value_3: '',
    value_3_text: ''
  });
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    axios.get(`${config.baseUrl}/complex-structures`)
      .then(response => {
        if (response.data.length > 0) {
          setData(response.data[0]);
        }
      })
      .catch(error => {
        console.error("There was an error fetching the data!", error);
        setError('Failed to fetch data. Please try again later.');
      });
  };

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleUpdate = () => {
    axios.post(`${config.baseUrl}/complex-structures`, data)
      .then(response => {
        fetchData();
      })
      .catch(error => {
        console.error("There was an error updating the data!", error);
        setError('Failed to update data. Please try again later.');
      });
  };

  return (
    <div className="complex-structure">
      <h1>Complex Structure Information</h1>
      {error && <div className="error-message">{error}</div>}
      <form className="complex-structure-form">
        <TextField
          label="Big Title"
          name="big_title"
          value={data.big_title}
          onChange={handleChange}
          fullWidth
          variant="outlined"
          margin="normal"
        />
        <TextField
          label="Secondary Title 1"
          name="secondary_title_1"
          value={data.secondary_title_1}
          onChange={handleChange}
          fullWidth
          variant="outlined"
          margin="normal"
        />
        <TextField
          label="Body 1"
          name="body_1"
          value={data.body_1}
          onChange={handleChange}
          fullWidth
          variant="outlined"
          margin="normal"
          multiline
          rows={4}
        />
        <TextField
          label="Secondary Title 2"
          name="secondary_title_2"
          value={data.secondary_title_2}
          onChange={handleChange}
          fullWidth
          variant="outlined"
          margin="normal"
        />
        <TextField
          label="Body 2"
          name="body_2"
          value={data.body_2}
          onChange={handleChange}
          fullWidth
          variant="outlined"
          margin="normal"
          multiline
          rows={4}
        />
        <TextField
          label="Secondary Title 3"
          name="secondary_title_3"
          value={data.secondary_title_3}
          onChange={handleChange}
          fullWidth
          variant="outlined"
          margin="normal"
        />
        <TextField
          label="Body 3"
          name="body_3"
          value={data.body_3}
          onChange={handleChange}
          fullWidth
          variant="outlined"
          margin="normal"
          multiline
          rows={4}
        />
        <div className="values-section">
          <div className="value-item">
            <TextField
              label="Value 1"
              name="value_1"
              value={data.value_1}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              margin="normal"
            />
            <TextField
              label="Value 1 Text"
              name="value_1_text"
              value={data.value_1_text}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              margin="normal"
            />
          </div>
          <div className="value-item">
            <TextField
              label="Value 2"
              name="value_2"
              value={data.value_2}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              margin="normal"
            />
            <TextField
              label="Value 2 Text"
              name="value_2_text"
              value={data.value_2_text}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              margin="normal"
            />
          </div>
          <div className="value-item">
            <TextField
              label="Value 3"
              name="value_3"
              value={data.value_3}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              margin="normal"
            />
            <TextField
              label="Value 3 Text"
              name="value_3_text"
              value={data.value_3_text}
              onChange={handleChange}
              fullWidth
              variant="outlined"
              margin="normal"
            />
          </div>
        </div>
        <Button variant="contained" color="primary" onClick={handleUpdate} className="update-button">
          Update Information
        </Button>
      </form>
    </div>
  );
};

export default ComplexStructure;
