import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../../config';
import './Dashboard.css';
import ArticleIcon from '@mui/icons-material/Article';
import CategoryIcon from '@mui/icons-material/Category';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import BuildIcon from '@mui/icons-material/Build';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import GroupIcon from '@mui/icons-material/Group';
import StoreIcon from '@mui/icons-material/Store';
import DriveEtaIcon from '@mui/icons-material/DriveEta';

const Dashboard = () => {
  const [counts, setCounts] = useState({
    blog_posts_count: 0,
    blog_categories_count: 0,
    body_types_count: 0,
    car_listings_count: 0,
    car_models_count: 0,
    manufacturers_count: 0,
    car_sales_count: 0,
    interested_users_count: 0,
    partners_count: 0,
    sell_your_cars_count: 0,
  });
  const [error, setError] = useState(null);

  useEffect(() => {
    axios.get(`${config.baseUrl}/statistics`)
      .then(response => {
        setCounts(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the counts!", error);
        setError('Failed to fetch counts. Please try again later.');
      });
  }, []);

  return (
    <div className="dashboard">
      <h1>Dashboard</h1>
      {error ? (
        <div className="error-message">{error}</div>
      ) : (
        <div className="dashboard-stats">
          <div className="stat-item">
            <ArticleIcon className="stat-icon" />
            <h2>Blog Posts</h2>
            <p>{counts.blog_posts_count}</p>
          </div>
          <div className="stat-item">
            <CategoryIcon className="stat-icon" />
            <h2>Blog Categories</h2>
            <p>{counts.blog_categories_count}</p>
          </div>
          <div className="stat-item">
            <DirectionsCarIcon className="stat-icon" />
            <h2>Body Types</h2>
            <p>{counts.body_types_count}</p>
          </div>
          <div className="stat-item">
            <DirectionsCarIcon className="stat-icon" />
            <h2>Car Listings</h2>
            <p>{counts.car_listings_count}</p>
          </div>
          <div className="stat-item">
            <AccountTreeIcon className="stat-icon" />
            <h2>Car Models</h2>
            <p>{counts.car_models_count}</p>
          </div>
          <div className="stat-item">
            <BuildIcon className="stat-icon" />
            <h2>Manufacturers</h2>
            <p>{counts.manufacturers_count}</p>
          </div>
          <div className="stat-item">
            <LocalOfferIcon className="stat-icon" />
            <h2>Car Sales</h2>
            <p>{counts.car_sales_count}</p>
          </div>
          <div className="stat-item">
            <GroupIcon className="stat-icon" />
            <h2>Interested Users</h2>
            <p>{counts.interested_users_count}</p>
          </div>
          <div className="stat-item">
            <StoreIcon className="stat-icon" />
            <h2>Partners</h2>
            <p>{counts.partners_count}</p>
          </div>
          <div className="stat-item">
            <DriveEtaIcon className="stat-icon" />
            <h2>Sell Your Cars</h2>
            <p>{counts.sell_your_cars_count}</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default Dashboard;
