import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Sidebar from './components/Sidebar/Sidebar';
import Dashboard from './components/Dashboard/Dashboard';
import CarListing from './pages/CarListing/CarListing';
import Manufacturers from './pages/Manufacturers/Manufacturers';
import CarModels from './pages/Models/CarModels';
import BodyTypes from './pages/BodyType/BodyTypes';
import Partners from './pages/Partners/Partners';
import Owners from './pages/Owners/Owners';
import Services from './pages/Services/Services';
import SellYourCars from './pages/SellYourCars/SellYourCars'; // Import the new component
import HomePageInformation from './pages/HomePageInformations/HomePageInformation';
import ComplexStructure from './pages/ComplexStructure/ComplexStructure';
import AboutUs from './pages/AboutUs/AboutUs';
import ContactUs from './pages/ContactUs/ContactUs'; // Import the new component
import BlogPage from './pages/BlogPage/BlogPage'; // Import the new component
import BlogCategoriesPage from './pages/BlogCategories/BlogCategoriesPage'; // Import the new component
import EditCar from './pages/CarListing/EditCar';
import Messages from './pages/Messages/Messages';

import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import './App.css';

const App = () => {
  const [sidebarVisible, setSidebarVisible] = useState(false);

  const toggleSidebarVisibility = () => {
    setSidebarVisible(!sidebarVisible);
  };

  const closeSidebarOnSmallScreens = () => {
    if (window.innerWidth <= 1024) {
      setSidebarVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', closeSidebarOnSmallScreens);
    return () => window.removeEventListener('resize', closeSidebarOnSmallScreens);
  }, []);

  return (
    <div className="app-container">
      <Router>
        <header className="header">
          <IconButton onClick={toggleSidebarVisibility} className="menu-button" style={{ color: 'white' }}>
            <MenuIcon />
          </IconButton>
          <h1 className="header-title">Admin Panel</h1>
        </header>
        <Sidebar visible={sidebarVisible} toggleSidebar={toggleSidebarVisibility} />
        <div className={`content ${sidebarVisible ? 'sidebar-open' : ''}`}>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/home-page-info" element={<HomePageInformation />} />
            <Route path="/complex-structure" element={<ComplexStructure />} />

            <Route path="/carlisting" element={<CarListing />} />
            <Route path="/manufacturers" element={<Manufacturers />} />
            <Route path="/models" element={<CarModels />} />
            <Route path="/body-types" element={<BodyTypes />} />
            <Route path="/partners" element={<Partners />} />
            <Route path="/owners" element={<Owners />} />
            <Route path="/services" element={<Services />} />
            <Route path="/sell-your-cars" element={<SellYourCars />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/blogs" element={<BlogPage />} /> {/* Add BlogPage route */}
            <Route path="/blog-categories" element={<BlogCategoriesPage />} /> {/* Add this line */}
            <Route path="/edit-car/:id" element={<EditCar />} />
            <Route path="/messages" element={<Messages />} />

          </Routes>
        </div>
      </Router>
    </div>
  );
};

export default App;
